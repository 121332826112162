import React from 'react'
import styled from 'styled-components'
import {
  TextField,
  Input,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Autocomplete,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

import EuroIcon from '@mui/icons-material/Euro'
import ButtonElement from './buttons'
import TypographyElement from './typography'
import CustomMonthDateRangePicker from './dateRangePicker'

const ariaLabel = { 'aria-label': 'description' }

const FormRowWrapper = styled.div`
  padding-bottom: 13px;
  ${(props) =>
    props.alignBottom &&
    `
    > div {
        vertical-align: bottom;
    }`}
`

export const FieldContainer = styled.div`
  /* padding-bottom: 13px; */
  ${(props) =>
    props.width == 'full' &&
    `
        width: 100%
    `}
  ${(props) =>
    props.width == 'mid' &&
    `
        width: 60%;
        display: inline-block;
        vertical-align: top;

        @media screen and (max-width: 780px) {
            width: 100%;
            display: block;
            padding-bottom: 30px;
        }
    `}
    ${(props) =>
    props.width == 'small' &&
    `
        width: 40%;
        display: inline-block;
        vertical-align: top;

        @media screen and (max-width: 780px) {
            width: 100%;
            display: block;
            padding-bottom: 30px;
        }
    `}
    ${(props) =>
    props.width == 'smallest' &&
    `
        display: inline-block;
        vertical-align: middle;
    `}
    &:nth-child(2n+2) {
    padding-left: 30px;

    @media screen and (max-width: 780px) {
      padding: 0;
    }
  }

  legend,
  fieldset {
    display: none !important;
  }
`

export const FormRow = ({ children, alignBottom = false }) => {
  return <FormRowWrapper alignBottom={alignBottom}>{children}</FormRowWrapper>
}

export const TextInput = ({
  value = '',
  label = 'Input label',
  required = true,
  placeholder,
  handleChange,
  id,
  width = 'full',
  name,
  error,
  handleBlur,
  boldlable = false,
  description = '',
}) => {
  const inputProps = {
    id: id,
    required: required,
    placeholder: placeholder,
    name: name,
  }
  return (
    <FieldContainer width={width}>
      <InputLabel
        required={required}
        htmlFor={id}
        error={error ? true : false}
        boldlable={boldlable ? 'true' : ''}
      >
        {label}
      </InputLabel>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}
      <Input
        id={id}
        value={value}
        inputProps={{ ...ariaLabel, ...inputProps }}
        placeholder={placeholder}
        onChange={handleChange}
        name={name}
        required={required}
        error={error ? true : false}
        onBlur={handleBlur}
      />
      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FieldContainer>
  )
}

export const NumberInput = ({
  value = '',
  label = '',
  required = true,
  placeholder,
  handleChange,
  id,
  width = 'full',
  name,
  error,
  handleBlur,
  boldlable = false,
  description = '',
}) => {
  return (
    <FieldContainer width={width}>
      <InputLabel
        required={required}
        htmlFor={id}
        error={error ? true : false}
        boldlable={boldlable ? 'true' : ''}
      >
        {label}
      </InputLabel>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}
      <Input
        id={id}
        value={value}
        inputProps={{
          ...ariaLabel,
          inputMode: 'numeric',
          pattern: '[0-9]*',
          required: required,
        }}
        placeholder={placeholder}
        onChange={(e) => {
          const regex = /^[0-9\b]+$/
          if (e.target.value === '' || regex.test(e.target.value)) {
            handleChange(e)
          }
        }}
        name={name}
        required={required}
        error={error ? true : false}
        onBlur={handleBlur}
      />
      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FieldContainer>
  )
}

export const MultiLineInput = ({
  value = '',
  label = 'Multi input label',
  description = '',
  required = true,
  placeholder,
  handleChange,
  id,
  width = 'full',
  name,
  error,
  handleBlur,
  boldlable = false,
}) => {
  return (
    <FieldContainer width={width}>
      <InputLabel
        required={required}
        htmlFor={id}
        error={error ? true : false}
        boldlable={boldlable ? 'true' : ''}
      >
        {label}
      </InputLabel>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}
      <Input
        id={id}
        multiline
        value={value}
        inputProps={ariaLabel}
        placeholder={placeholder}
        onChange={handleChange}
        name={name}
        required={required}
        error={error ? true : false}
        onBlur={handleBlur}
      />
      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FieldContainer>
  )
}

export const SelectInput = ({
  label = 'Select label',
  description = '',
  required = false,
  handleChange,
  id,
  value,
  options,
  width = 'full',
  placeholder,
  issearch = false,
  name,
  error,
  handleBlur,
  subchoicesError,
  addRequiredField,
  removeRequiredField,
  multiple = false,
  boldlable = false,
  withEmpty = false,
}) => {
  const [selectedSubchoice, setSelectedSubchoice] = React.useState('')

  const pcs = React.useMemo(() => {
    if (!options) return []
    const useOptions = withEmpty
    ? [
        {
          value: '',
          name: '-',
          disabled: true,
        },
        ...options,
      ]
    : options
    return useOptions?.map((item) => {
      return (
        <MenuItem value={item.value} key={item.value} disabled={item.disabled}>
          {item.name}
        </MenuItem>
      )
    })
  }, [options, withEmpty])

  const subchoices = React.useMemo(() => {
    // Get the subchoices for the selected item
    if (!options || !value) return []

    const selectedItem = options.find((item) => item.value === value)
    if (!(selectedItem?.subchoices && selectedItem?.subchoices.length > 0)) {
      return []
    }

    return selectedItem?.subchoices?.map((item) => {
      return (
        <MenuItem value={item.value} key={item.value} disabled={item.disabled}>
          {item.label}
        </MenuItem>
      )
    })
  }, [options, value])

  React.useEffect(() => {
    if (addRequiredField && subchoices?.length > 0) {
      addRequiredField(`${name}_subchoice`)
    } else if (removeRequiredField) {
      removeRequiredField(`${name}_subchoice`)
    }
  }, [subchoices, addRequiredField, removeRequiredField])

  return (
    <FieldContainer width={width}>
      <InputLabel
        required={required}
        htmlFor={id}
        error={error ? true : false}
        boldlable={boldlable ? 'true' : ''}
      >
        {label}
      </InputLabel>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}
      <Select
        id={id}
        onChange={handleChange}
        value={value}
        label={label}
        placeholder={placeholder}
        issearch={issearch ? 'true' : ''}
        name={name}
        required={required}
        error={error ? true : false}
        onBlur={handleBlur}
        multiple={multiple}
        sx={{ paddingRight: '0 !important' }}
      >
        {placeholder && (
          <MenuItem value="" key="placeholder" disabled={true}>
            {placeholder}
          </MenuItem>
        )}
        {pcs}
      </Select>
      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}

      {/* When the user selects a choice with subchoices, show the subchoices */}
      {subchoices?.length > 0 && <>
        <InputLabel
          required={true}
          htmlFor={"subchoice"}
          error={subchoicesError ? true : false}
          boldlable={boldlable ? 'true' : ''}
        >
          Onderdeel
        </InputLabel>
        <Select
          id={"subchoice"}
          name={`${name}_subchoice`}
          onChange={(e) => {
            handleChange(e)
            setSelectedSubchoice(e.target.value)
          }}
          value={selectedSubchoice}
          label={"Onderdeel"}
          placeholder={"Selecteer een onderdeel"}
          issearch={false}
          required={true}
          error={subchoicesError ? true : false}
          onBlur={handleBlur}
          multiple={false}
          sx={{ paddingRight: '0 !important' }}
        >
          <MenuItem value="" key="placeholder" disabled={true}>
            Selecteer een onderdeel
          </MenuItem>
          {subchoices}
        </Select>
        {subchoicesError && (
          <FormHelperText error={subchoicesError ? true : false} id="component-error-text">
            {subchoicesError}
          </FormHelperText>
        )}
      </>}
    </FieldContainer>
  )
}

export const DatePickerField = ({
  label = 'Date label',
  required = true,
  handleChange,
  id,
  value,
  width = 'mid',
  name,
  minDate,
  error,
  handleBlur,
  boldlable = false,
  description = '',
}) => {
  const [open, setOpen] = React.useState(false)
  return (
    <FieldContainer width={width}>
      <InputLabel
        required={required}
        htmlFor={id}
        error={error ? true : false}
        boldlable={boldlable ? 'true' : ''}
      >
        {label}
      </InputLabel>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}
      <DatePicker
        className="datepicker"
        id={id}
        inputFormat="dd-MM-yyyy"
        value={value || ''}
        onChange={(e, kbinput) => {
          handleChange(e, kbinput)
          setOpen(false)
        }}
        minDate={minDate}
        open={open}
        dayOfWeekFormatter={(day) => {
          return day.toUpperCase()
        }}
        PaperProps={{
          role: 'datepicker',
        }}
        InputProps={{
          id: id,
          name: name,
          'data-type': 'datepicker',
          required: required,
          error: error && true,
          onBlur: handleBlur,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onClick={() => setOpen(!open)}
            onTouchEnd={() => setOpen(!open)}
            error={error ? true : false}
            onBlur={handleBlur}
          />
        )}
      />
      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FieldContainer>
  )
}

export const TimePickerField = ({
  label = 'Date label',
  required = true,
  handleChange,
  id,
  value,
  width = 'small',
  name,
  error,
  handleBlur,
  boldlable = false,
  description = '',
}) => {
  return (
    <FieldContainer width={width}>
      <InputLabel
        required={required}
        id={id}
        htmlFor={id}
        error={error ? true : false}
        boldlable={boldlable ? 'true' : ''}
      >
        {label}
      </InputLabel>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}
      <TimePicker
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        ampm={false}
        minutesStep={5}
        InputAdornmentProps={{
          position: 'start',
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            onBlur={handleBlur}
            error={error ? true : false}
            name={name}
          />
        )}
      />
      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FieldContainer>
  )
}

export const DateRangeInput = ({
  name,
  label,
  required,
  id,
  value,
  error,
  handleChange = () => {},
  handleBlur = () => {},
  boldlable = false,
  description = '',
  width = 'full',
}) => {
  const [selectionRange, setSelectionRange] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  const hiddenValue = value
    ? {
        start: value?.startDate,
        eind: value?.endDate,
      }
    : null

  return (
    <FieldContainer width={width}>
      <InputLabel
        required={required}
        id={id}
        htmlFor={id}
        error={error ? true : false}
        boldlable={boldlable ? 'true' : ''}
      >
        {label}
      </InputLabel>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}
      <CustomMonthDateRangePicker
        id={id}
        doubleCalendar={false}
        focusDate={new Date()}
        minDate={new Date()}
        handleSelection={handleChange}
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
        singledate={false}
        selectedSingleDate={null}
      />
      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}
      <input type="hidden" name={name} value={hiddenValue} />
    </FieldContainer>
  )
}

export const PriceInput = ({
  value = '',
  label = 'Prijs (voer 0 in voor gratis informatie)',
  required,
  placeholder,
  handleChange,
  id,
  width = 'mid',
  name,
  error,
  handleBlur,
  boldlable = false,
  description = '',
}) => {
  return (
    <FieldContainer width={width}>
      <InputLabel
        required={required}
        htmlFor={id}
        error={error ? true : false}
        boldlable={boldlable ? 'true' : ''}
      >
        {label}
      </InputLabel>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}
      <Input
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        required={required}
        inputProps={{
          'aria-label': 'description',
          inputMode: 'numeric',
          pattern: '[0-9]*',
          required: required,
        }}
        startAdornment={
          <InputAdornment position="start">
            <EuroIcon />
          </InputAdornment>
        }
        name={name}
        error={error ? true : false}
        onBlur={handleBlur}
      />
      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FieldContainer>
  )
}

export const EmailInput = ({
  value = '',
  label = 'E-mailadres',
  required,
  placeholder,
  handleChange,
  id,
  width = 'full',
  name,
  error,
  handleBlur,
  boldlable = false,
  description,
}) => {
  return (
    <FieldContainer width={width}>
      <InputLabel
        required={required}
        htmlFor={id}
        error={error ? true : false}
        boldlable={boldlable ? 'true' : ''}
      >
        {label}
      </InputLabel>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}
      <Input
        type="email"
        id={id}
        inputProps={ariaLabel}
        placeholder={placeholder}
        onChange={handleChange}
        name={name}
        required={required}
        error={error ? true : false}
        onBlur={handleBlur}
        value={value}
      />
      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FieldContainer>
  )
}

export const TelephoneInput = ({
  value = '',
  label = 'Telefoonnummer',
  required,
  placeholder,
  handleChange,
  id,
  width = 'full',
  name,
  error,
  handleBlur,
  boldlable = false,
  description = '',
}) => {
  return (
    <FieldContainer width={width}>
      <InputLabel
        required={required}
        htmlFor={id}
        error={error ? true : false}
        boldlable={boldlable ? 'true' : ''}
      >
        {label}
      </InputLabel>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}
      <Input
        type="tel"
        id={id}
        value={value}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          required: required,
        }}
        placeholder={placeholder}
        onChange={(e) => {
          const regex = /^[0-9\b]+$/
          if (e.target.value === '' || regex.test(e.target.value)) {
            handleChange(e)
          }
        }}
        name={name}
        required={required}
        error={error ? true : false}
        onBlur={handleBlur}
      />
      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FieldContainer>
  )
}

export const CheckBoxField = ({
  label = 'Ik ga akkoord',
  handleChange,
  id,
  width = 'full',
  disabled = false,
  checked,
  checkColor,
  name,
  description,
}) => {
  return (
    <FieldContainer width={width}>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}
      <FormControlLabel
        id={id}
        disabled={disabled}
        control={
          <Checkbox
            onChange={handleChange}
            checked={checked}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
              '&.Mui-checked': {
                color: checkColor,
              },
            }}
          />
        }
        label={label}
        name={name}
      />
    </FieldContainer>
  )
}

export const UploadField = ({
  label = 'Upload',
  handleChange,
  accept = 'image/*',
  disabled = false,
  error = null,
}) => {
  const ref = React.useRef()
  return (
    <FieldContainer>
      <ButtonElement
        variant="upload"
        component="label"
        disabled={disabled}
        handleClick={() => ref?.current?.click()}
      >
        {label}
      </ButtonElement>
      <input
          ref={ref}
          type="file"
          accept={accept}
          hidden
          onChange={handleChange}
        />
      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FieldContainer>
  )
}

export const UploadDocField = ({
  label = 'Uploaden',
  handleChange,
  id,
  accept = 'image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  disabled = false,
  error = null,
  description = '',
  boldlable = false,
  selectedFile = null,
}) => {
  return (
    <FieldContainer>
      <InputLabel
        required={false}
        htmlFor={id}
        error={error ? true : false}
        boldlable={boldlable ? 'true' : ''}
      >
        {label}
      </InputLabel>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}
      {selectedFile && (
        <TypographyElement variant="p">
          Bestandnaam: {selectedFile?.name}
        </TypographyElement>
      )}
      <ButtonElement variant="upload" component="label" disabled={disabled}>
        Uploaden
        <input
          type="file"
          id={id}
          accept={accept}
          hidden
          onChange={handleChange}
        />
      </ButtonElement>
      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FieldContainer>
  )
}

export const MultiUploadDocField = ({
  label = 'Uploaden',
  handleChange,
  id,
  accept = 'image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  disabled = false,
  error = null,
  description = '',
  boldlable = false,
  selectedFiles = [],
  handleRemoveFile,
  required = false,
}) => {
  return (
    <FieldContainer>
      <InputLabel
        required={required}
        htmlFor={id}
        error={error ? true : false}
        boldlable={boldlable ? 'true' : ''}
      >
        {label}
      </InputLabel>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}
      <ButtonElement variant="upload" component="label" disabled={disabled}>
        Uploaden
        <input
          type="file"
          id={id}
          accept={accept}
          hidden
          onChange={handleChange}
          multiple={true}
        />
      </ButtonElement>
      
      <div>
      {selectedFiles?.map((file, ind) => {
          return (
            <div key={`file-avent-${ind}`} className="image-preview multi">
              <TypographyElement variant="p">
                Bestandnaam: {file?.name}
              </TypographyElement>
              <div
                className="remove"
                tabIndex={0}
                onClick={() => handleRemoveFile(file)}
              >
                x
              </div>
            </div>
          )
      })}
      </div>

      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FieldContainer>
  )
}

export const AutocompleteInput = ({
  label = 'Select label',
  description = '',
  required = false,
  handleChange,
  id,
  value,
  options,
  width = 'full',
  placeholder,
  name,
  error,
  handleBlur,
  multiple = false,
  boldlable = false,
}) => {
  const mappedValue = value?.map((item) => item.id) || []
  const hiddenValue = mappedValue?.join(', ')

  return (
    <FieldContainer width={width}>
      <InputLabel
        required={required}
        htmlFor={id}
        error={error ? true : false}
        boldlable={boldlable ? 'true' : ''}
      >
        {label}
      </InputLabel>
      {description && (
        <TypographyElement variant="p">{description}</TypographyElement>
      )}

      <Autocomplete
        multiple={multiple}
        id={id}
        options={options}
        getOptionLabel={(option) => option.name}
        defaultValue={[]}
        value={value}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label=""
            placeholder={placeholder}
          />
        )}
      />

      <input type="hidden" name={name} value={hiddenValue} />
      {error && (
        <FormHelperText error={error ? true : false} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FieldContainer>
  )
}

const fieldDict = {
  text: TextInput,
  number: NumberInput,
  multiline: MultiLineInput,
  select: SelectInput,
  date: DatePickerField,
  time: TimePickerField,
  price: PriceInput,
  email: EmailInput,
  telephone: TelephoneInput,
  checkbox: CheckBoxField,
  upload: UploadField,
  upload_doc: UploadDocField,
  multi_upload_doc: MultiUploadDocField,
  autocomplete: AutocompleteInput,
  daterange: DateRangeInput,
  multiselect: SelectInput,
}

const Field = ({ variant = 'text', children, ...restProps }) => {
  if (!(variant in fieldDict)) {
    variant = 'text'
  }
  const Element = fieldDict[variant]
  return <Element {...restProps}>{children}</Element>
}

export default Field
