import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import moment from 'moment'
import { DateRangePicker } from 'react-date-range'
import styled from 'styled-components'
import { nl } from 'date-fns/locale'
import { black, quarternary_black, secondary_green } from '../colors'

const Wrapper = styled.div`
  .daterange-picker {
    display: block;

    .rdrDefinedRangesWrapper {
      display: none;
    }

    /* wrapper */
    .rdrDateRangeWrapper {
      width: ${(props) => (props.doubleCalendar ? '987px' : '449px')};
      max-width: 100%;

      .rdrMonths {
        width: 100%;
        .rdrMonth {
          width: 100%;
          ${(props) =>
            props.doubleCalendar &&
            `
            margin-top: -90px;
          `}
        }
      }
    }

    /* month and year */
    .rdrMonthAndYearWrapper {
      ${(props) =>
        props.doubleCalendar &&
        `
          background-color: #97C9DE33;
          height: 87px;
        `}
      .rdrMonthAndYearPickers {
        font-family: 'Dosis';
        font-weight: 700;
        font-size: 30px;
        line-height: 44px;
        color: ${black};
        text-transform: uppercase;
        ${(props) =>
          props.doubleCalendar &&
          `
          display: none;
        `}
      }
      .rdrNextPrevButton {
        background: transparent;
        width: 38px;
      }
      .rdrPprevButton {
        ${(props) =>
          props.doubleCalendar
            ? `
        i {
          border-width: 6px 9px 6px 6px;
          border-color: transparent ${quarternary_black} transparent transparent;
          transform: translate(-3px, 0px);
        }`
            : `
        i {
          border-width: 6px 9px 6px 6px;
          border-color: transparent ${secondary_green} transparent transparent;
          transform: translate(-3px, 0px);
        }`}
      }
      .rdrNextButton {
        ${(props) =>
          props.doubleCalendar
            ? `
        i {
          margin: 0 0 0 7px;
          border-width: 6px 6px 6px 9px;
          border-color: transparent transparent transparent ${quarternary_black};
          transform: translate(3px, 0px);
        }`
            : `
        i {
          margin: 0 0 0 7px;
          border-width: 6px 6px 6px 9px;
          border-color: transparent transparent transparent ${secondary_green};
          transform: translate(3px, 0px);
        }`}
      }
    }
    .rdrMonthName {
      ${(props) =>
        props.doubleCalendar
          ? `
        display: block;
        text-align: center;
        margin-bottom: 20px;
        font-family: 'Dosis';
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        color: #1D1D1B;
        text-transform: uppercase;
      `
          : `
        display: none;
      `}
    }

    .rdrWeekDays {
      .rdrWeekDay {
        text-transform: uppercase;
        font-family: 'Dosis';
        font-weight: 700;
        font-size: 20px;
        line-height: 30x;
        color: ${black};
      }
    }

    .rdrDays {
      .rdrDay {
        height: 55px;
      }
      .rdrDayDisabled {
        background-color: transparent;
      }
      .rdrDayToday {
        .rdrDayNumber {
          span {
            font-weight: 700;
          }
        }
      }
      .rdrDayNumber {
        font-family: 'Dosis';
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        span:after {
          display: none;
        }
      }
      .rdrEndEdge {
        border-radius: 0;
      }
      .rdrInRange {
        color: rgba(228, 6, 19, 0.4);
        border-radius: 0;
      }
      .rdrStartEdge {
        border-radius: 0;
      }
      .rdrDayStartPreview,
      .rdrDayEndPreview {
        border-radius: 0;
      }
      ${(props) =>
        props.singledate &&
        `
        .rdrDayInPreview {
          display: none;
        }
        .rdrDayStartPreview,
        .rdrDayEndPreview {
          border: 1px solid #E40613;
        }
      `}
    }
  }
`
const CustomMonthDateRangePicker = ({
  doubleCalendar = false,
  focusDate = new Date(),
  minDate = new Date(),
  handleSelection,
  selectionRange,
  setSelectionRange,
  singledate = false,
  selectedSingleDate = null,
  id = 'custom_month_daterange_picker',
}) => {
  const handleSelectRange = (ranges) => {
    setSelectionRange(ranges.selection)
    handleSelection(ranges.selection)
  }

  const handleSelectSingleDate = ({ selection }) => {
    const { startDate, endDate } = selection
    const end = moment(endDate).format('LL')
    const current = moment(selectedSingleDate).format('LL')

    let singleSelection
    if (end == current) {
      singleSelection = { ...selection, endDate: startDate }
    } else {
      singleSelection = { ...selection, startDate: endDate }
    }

    setSelectionRange(singleSelection)
    handleSelection(singleSelection)
  }

  const handleSelect = singledate ? handleSelectSingleDate : handleSelectRange

  return (
    <Wrapper doubleCalendar={doubleCalendar} singledate={singledate}>
      <DateRangePicker
        id={id}
        locale={nl}
        rangeColors={singledate ? 'transparent' : '#E40613'}
        color="#E40613"
        ranges={[selectionRange]}
        onChange={handleSelect}
        months={doubleCalendar ? 2 : 1}
        className="daterange-picker"
        shownDate={focusDate}
        minDate={minDate}
        direction="horizontal"
        showMonthAndYearPickers={false}
        showDateDisplay={false}
        weekdayDisplayFormat="EEEEEE"
        showSelectionPreview={!singledate}
        showPreview={!singledate}
        dragSelectionEnabled={!singledate}
      />
    </Wrapper>
  )
}

export default CustomMonthDateRangePicker
